<template>
  <df-modal
    data-id="modal-add-sowing"
    persistent
    :action-name="modalAction"
    :loading="loadingFormAPI"
    :title="modalTitle"
    @action-click="addEditSowing"
    @close="closeAddSowingModal"
  >
    <template #card-content>
      <sowing-form
        ref="sowingForm"
        :sowing-id="sowingId"
        @close="closeAddSowingModal"
        @loading="loadingForm"
      />
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'
import SowingForm from '@/modules/sowingsPlanning/components/SowingForm.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'AddSowingModal',

  components: {
    DfModal,
    SowingForm,
  },

  props: {
    sowingId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      loadingFormAPI: false,
    }
  },

  inject: {
    requestFieldSowingsPlanning: {
      default: () => () => {},
      from: 'requestFieldSowingsPlanning',
    },
  },

  computed: {
    modalAction() {
      return this.sowingId
        ? this.$t('SowingsPlanning.add_sowing_modal.edit')
        : this.$t('SowingsPlanning.add_sowing_modal.add')
    },
    modalTitle() {
      return this.sowingId
        ? this.$t('SowingsPlanning.add_sowing_modal.edit_sowing')
        : this.$t('SowingsPlanning.add_sowing_modal.add_sowing')
    },
  },

  methods: {
    async addEditSowing() {
      const sowingFormComponent = this.$refs.sowingForm
      const vForm = sowingFormComponent.$refs.form
      if (!vForm.validate()) return
      logEvent(events.sowingsPlanningModule.SowingsPlanning.finish_add_sowing)
      this.loadingFormAPI = true
      try {
        if (this.sowingId) {
          const editParams = {
            estimated_cycle: sowingFormComponent.cycle,
            estimated_start_date: sowingFormComponent.sowingEstimatedDate,
            sowing_id: this.sowingId,
          }
          await sowingsPlanningService.editSowingPlanning(editParams)
        } else {
          const addParams = {
            task: {
              type: 'sowing',
              estimated_start_date: sowingFormComponent.sowingEstimatedDate,
              estimated_completed_date: sowingFormComponent.sowingEstimatedDate,
              account_id: sowingFormComponent.currentUser.account.id,
              created_by: sowingFormComponent.currentUser.id,
              status_name: 'planning',
              extra_info: {
                estimated_cycle: sowingFormComponent.cycle,
                variety_id: Number(sowingFormComponent.selectedGenetic.value),
                variety_name: sowingFormComponent.selectedGenetic.name,
                asset_owner_name: sowingFormComponent.companyName,
              },
            },
            crop_zone: {
              crop_id: Number(sowingFormComponent.selectedCrop.value),
              variety_id: Number(sowingFormComponent.selectedGenetic.value),
              farm_id: Number(sowingFormComponent.farmId),
              field_id: sowingFormComponent.selectedFields.map((field) =>
                Number(field.value)
              ),
              status_name: 'planning',
            },
          }
          await sowingsPlanningService.postSowingPlanning(addParams)
        }
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsPlanning.add_sowing_modal.successful_request_title')
        )
        if (this.requestFieldSowingsPlanning) this.requestFieldSowingsPlanning()
        this.closeAddSowingModal()
      } catch (error) {
        console.error(error)
        this.$root.$emit(
          'notify',
          'error',
          this.$t('exception.unexpected_error_title'),
          this.$t('SowingsPlanning.exceptions.add_sowing')
        )
      } finally {
        this.loadingFormAPI = false
      }
    },
    closeAddSowingModal() {
      this.$emit('close')
    },
    loadingForm(value) {
      this.loadingFormAPI = value
    },
  },
}
</script>
